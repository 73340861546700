import React from 'react'
import Abouthero from './Abouthero'
import Whatwedo from './Whatwedo'
import Keyfigures from './Keyfigures'

const Aboutpage = () => {
  return (
    <div>
      <Abouthero />
      <Whatwedo />
      <Keyfigures />
    </div>
  )
}

export default Aboutpage